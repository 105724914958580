import {BackIcon} from "../atoms/icons/backIcon";
import {Button} from "../atoms/button";
import {NextIcon} from "../atoms/icons/nextIcon";
import Link from "next/link";
import {FC} from "react";


export const CheckoutFormFooter: FC<{label: string, href?: string|null}> = ({label, href = null}) => (
    <div className="flex items-center col-span-2 justify-between mt-12">
        <Link
            href={href === null ? '/bezorging' : href}>
            <a className="text-black font-bold inline-flex items-center gap-2 rounded-full focus:outline-none">
                <BackIcon/>
                Vorige stap
            </a>
        </Link>
        <Button type={'submit'}>
            {label}
            <NextIcon/>
        </Button>
    </div>
)