import {useField} from "formik";
import {FC, useEffect, useRef, useState} from "react";
import {DayPicker} from "react-day-picker";
import FocusTrap from 'focus-trap-react';
import {ActiveModifiers} from "react-day-picker/src/types/Modifiers";
import {usePopper} from "react-popper";
import nl from 'date-fns/locale/nl';
import moment from 'moment';
import 'moment/locale/nl';

interface dateFieldProps {
    name: string;
    placeholder: string;
    initialValue: string;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
    currentValue:string;
}

export const DateField: FC<dateFieldProps> = ({name, placeholder, setFieldValue, initialValue, currentValue}) => {
    const [field, meta] = useField(name);

    const [dateValue, setDateValue] = useState(initialValue);

    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const popperRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        if(moment(initialValue, 'DD-MM-YYYY').isBefore(moment(currentValue, 'DD-MM-YYYY'))){
            setDateValue(currentValue);
            setFieldValue('shippingDate', currentValue);
        }else{
            setFieldValue('shippingDate', initialValue);
        }
    }, [initialValue])

    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
        null
    );

    const popper = usePopper(popperRef.current, popperElement, {
        placement: 'bottom-start'
    });

    const closePopper = () => {
        setIsDatePickerOpen(false);
        buttonRef?.current?.focus();
    };

    return (
        <span>
            <div ref={popperRef}>
            <input
                className={`w-[126px] cursor-pointer rounded-lg border-2 border-green focus:ring-0 focus:border-green text-green font-bold focus:ring-green ${meta.touched && meta.error ? 'border-red' : 'border-green'}`}
                {...field}
                name={name}
                id={name}
                type={'text'}
                placeholder={placeholder}
                readOnly={true}
                value={dateValue.toString()}
                onClick={() => {setIsDatePickerOpen(true)}}
            />
            </div>
            {isDatePickerOpen && (
                <FocusTrap
                    active
                    focusTrapOptions={{
                        initialFocus: false,
                        allowOutsideClick: true,
                        clickOutsideDeactivates: true,
                        onDeactivate: closePopper
                    }}
                >
                    <div
                        tabIndex={-1}
                        style={popper.styles.popper}
                        className="mt-2 bg-white rounded-2xl shadow-lg"
                        {...popper.attributes.popper}
                        ref={setPopperElement}
                        role="dialog"
                    >
                        <DayPicker
                            locale={nl}
                            disabled={[
                                {dayOfWeek: [0,1,6]},
                                {before: moment(initialValue,'DD-MM-YYYY').toDate()}
                            ]}
                            mode="single"
                            defaultMonth={moment(dateValue, 'DD-MM-YYYY').toDate()}
                            selected={moment(dateValue, 'DD-MM-YYYY').toDate()}
                            onSelect={(day: Date| undefined, selectedDay: Date, activeModifiers: ActiveModifiers, e: React.MouseEvent) => {
                                setDateValue(moment(day).locale('nl').format('DD-MM-YYYY'))
                                setFieldValue('shippingDate',moment(day).locale('nl').format('DD-MM-YYYY'))
                                closePopper()
                            }
                        }
                        />
                    </div>
                </FocusTrap>
            )}

            {meta.touched && meta.error ? (
                <div className="col-span-5 font-medium text-red text-12 pt-2">
                    {meta.error}
                </div>
            ) : null}
        </span>
    );
};