import Select, {components} from 'react-select';
import {DropdownIndicatorIcon} from "../../atoms/icons/dropdownIndicatorIcon";
import {useGetPaymentMethods} from "../../../hooks/useGetPaymentMethods";
import Image from "next/image";
import {FC} from "react";

const customStyles = {
    control: (provided: any, state: any) => ({
        ...provided,
        border: '2px solid #0ead69',
        borderRadius: '.5rem',
        paddingRight: '.5rem',
        boxShadow: 'none',
        color: '#2b2b2b',
        "&:hover": {
            outline: 'none'
        },
        "&:focus": {
            outline: 'none'
        }
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        display: 'inline-flex'
    })
}

const CustomOption = (props: any) => {
    return (
        <components.Option {...props} className={'inline-flex gap-2'}>
            <Image src={props.data.image} alt={`${props.data.label} logo`} priority={true} width={32} height={24}/>
            {props.data.label}
        </components.Option>
    )
};

interface PaymentSelectProps {
    valueChange: (value: string) => void
}

export const PaymentSelect: FC<PaymentSelectProps> = ({valueChange}) => {
    const [methods] = useGetPaymentMethods();

    return (
        <div className={'w-full md:w-9/12'}>
            <label className={`text-black font-bold mb-2 block`}>Bank selecteren (iDEAL):</label>
            <Select
                options={methods}
                onChange={(event) => event !== null ? valueChange(event.value) : null}
                isSearchable={false}
                isClearable={false}
                placeholder='Selecteer je bank'
                styles={customStyles}
                components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator: DropdownIndicatorIcon,
                    Option: CustomOption
                }}
            />
        </div>
    )
}