import {NewOrderFormValues} from "../types/newOrderFormValues";
import {BackendClient} from "../utils/backendClient";
import {ShoppingCartItemInterface} from "../types/shoppingCartItemInterface";
import {OrderInputDtoCategoryEnum} from "../generated-sources/openapi";

type Hook = () => [
    (values: NewOrderFormValues, cart: ShoppingCartItemInterface[], category?: OrderInputDtoCategoryEnum, token?: string, id?: string, ebook?: boolean) => void
]

export const useOrderCheckout: Hook = () => {
    const checkout = async (values: NewOrderFormValues, cart: ShoppingCartItemInterface[], category: OrderInputDtoCategoryEnum = OrderInputDtoCategoryEnum.NewCustomersV5, token?: string, id?: string, ebook = false) => {
        try {
            const result = await BackendClient.orderControllerNewOrder({
                orderInputDto: {
                    deliveryFrequency: Number(values.deliveryFrequency),
                    paymentFrequency: Number(values.paymentFrequency),
                    gift: values.gift,
                    giftMessage: values.giftMessage,
                    name: values.fullName,
                    zipcode: values.zipcode.replace(/\s+/g, ''),
                    number: values.number,
                    deliveryMethod: values.deliveryMethod,
                    issuer: values.paymentMethod as string,
                    email: values.email,
                    phone: values.phone,
                    newsPaper: values.newsPaper,
                    items: cart,
                    category: category,
                    changeToken: token,
                    subscriptionId: id,
                    note: values.note,
                    startDate: values.shippingDate,
                    ebook: ebook
                }
            })

            location.assign(result.checkoutUrl);
        } catch (e: any) {
            console.log(e);
        }
    }

    return [checkout]
}