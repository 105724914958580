import {useEffect, useState} from "react";

interface Method {
    resource: string
    id: string
    name: string
    image: IssuerIcon
}

interface IssuerIcon {
    size1x: string
    size2x: string
    svg: string
}

type Hook = () => [
    { value: string, label: string, image: string }[]
]

export const useGetPaymentMethods: Hook = () => {
    const [methods, setMethods] = useState<{ value: string, label: string, image: string }[]>([]);

    useEffect(() => {
        fetch(`${process.env.NEXT_PUBLIC_API_HOST}payment/issuers`).then(
            res => {
                if (res.ok) {
                    res.json().then((data: Method[]) => {
                        setMethods(data.map(item => ({value: item.id, label: item.name, image: item.image.size1x})));
                    });
                }
            }
        ).catch(e => console.error(e))
    }, []);

    return [methods];
}